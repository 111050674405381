<template>
<div>
  <div class="exception">
    <div class="imgbox">
      <img src="../../../assets/404.svg" alt />
    </div>
    <div class="exception-content">
      <h1>404</h1>
      <span class="exception-404">抱歉，你访问的页面不存在</span>
      <div>
        <Button type="primary" @click="goHome">返回首页</Button>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  methods: {
    goHome() {
      this.$router.replace({
        name: "Workbench"
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.exception {
  display: flex;
  height: 80%;
  min-height: 500px;
  align-items: center;

  .imgbox {
    width: 50%;
    flex: 0 0 50%;
    zoom: 1;
    padding-right: 152px;

    img {
      width: 430px;
      height: 360px;
      float: right;
    }
  }

  .exception-content {
    flex: auto;

    h1 {
      margin-bottom: 24px;
      font-weight: 600;
      color: #515a6e;
      font-size: 72px;
      line-height: 72px;
    }

    .exception-404 {
      margin-bottom: 16px;
      color: #808695;
      font-size: 20px;
      line-height: 28px;
      display: block;
    }
  }
}
</style>
